import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginRequest } from 'src/app/models/dto/login-request';
import { AuthService } from 'src/app/services/auth.service';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';

/**
 * LoginComponent to display the Login to the System
 * if a user is already logged in, will automatically forward to internal pages
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  /**
   * loading indication
   */
  loading: boolean = false;

  /**
   * Login form
   */
  loginForm: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl('', Validators.required),
    password: new UntypedFormControl('', Validators.required),
  });

  /**
   * constructor
   *
   * @param _loginService injected
   * @param _router injected
   * @param _authService injected
   */
  constructor(
    private _loginService: LoginService,
    private _router: Router,
    private _authService: AuthService,
    private _notificationService: NotificationService,
    private _translateService: TranslateService
  ) {
    this.checkIfAuthorized();
  }

  /**
   * ngOnInit function
   */
  ngOnInit() {
    this.checkIfAuthorized();
  }

  /**
   * login function triggers the login request to the backend with given inputs
   * and handles the response accordingly
   */
  login() {
    this.loading = true;

    const loginRequest: LoginRequest = {
      username: this.loginForm.get('username')?.value,
      password: this.loginForm.get('password')?.value,
    };

    this._loginService.postLogin(loginRequest).subscribe((response) => {
      this.loading = false;

      if (response.success) {
        this.checkIfAuthorized();
      } else {
        // TODO: make this an error inline in the login
        this._notificationService.addMessage({
          text: this._translateService.instant("login.error")
        });
      }
    });
  }

  /**
   * function to check if the current user is already authorized.
   * Will forward to an internal site if already logged in
   */
  private checkIfAuthorized() {
    if (this._authService.hasToken()) {
      switch (this._authService.role) {
        case 'Admin':
          this._router.navigate(['/overview']);
          break;
        case 'Client':
          // TODO: put in uuid of client
          this._router.navigate(['/dashboard/client/id']);
          break;
        case 'Customer':
          // TODO: put in uuid of customer
          this._router.navigate(['/dashboard/customer/id']);
          break;
      }
    }
  }
}
