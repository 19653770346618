import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Company } from '../models/company';
import { Contact } from '../models/contact';
import { User } from '../models/user';
import { UserDto } from '../models/user-dto';

/**
 * AuthService handles authentication with the backend
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * property holding the current user information
   */
  private _user: User = {
    role: 'Guest',
    token: '',
    company: {
      id: 0,
      guid: '',
      name: ''
    },
    contact: {},
    user: {
      username: "",
      e_mail: "",
      guid: "",
      id: 0
    }
  };

  /**
   * constructor
   *
   * @param _cookieService injected
   */
  constructor(private _cookieService: CookieService
  ) {
    // load initial data from cookie if present
    if (this._cookieService.check('user')) {
      this._user = JSON.parse(this._cookieService.get('user'));
    }
  }

  /**
   * check if user has a auth token
   * this does not check if the token is still valid!
   *
   * @returns
   */
  hasToken() {
    return this._user.token !== '';
  }

  /**
   * helper function to update the cookie data
   */
  private updateCookie() {
    this._cookieService.set('user', JSON.stringify(this._user));
  }

  /**
   * resets the current user information and deletes the cookie
   */
  reset() {
    this._user = {
      role: 'Guest',
      token: '',
      company: {
        id: 0,
        guid: '',
        name: ''
      },
      contact: {},
      user: {
        username: "",
        e_mail: "",
        guid: "",
        id: 0
      }
    };

    this._cookieService.deleteAll();
  }

  /**
   * setter for the role
   * updates the cookie
   */
  set role(value: string) {
    this._user.role = value;
    this.updateCookie();
  }

  /**
   * setter for the auth token
   * updates the cookie
   */
  set token(value: string) {
    this._user.token = value;
    this.updateCookie();
  }

  /**
   * setter for the company
   * updates the cookie
   */
  set company(value: Company) {
    this._user.company = value;
    this.updateCookie();
  }

  setAfterUpdate( username: string, email: string, firstname: string, lastname: string){
    this._user.contact.firstname = firstname
    this._user.contact.lastname = lastname
    this._user.user.username = username
    this._user.user.e_mail = email
    this.updateCookie();
  }

  set user(value: UserDto) {
    this._user.user = value
    this.updateCookie();
  }

  /**
   * setter for contact info
   * updates the cookie
   */
  set contact(value: Contact) {
    this._user.contact = value;
    this.updateCookie();
  }

  /**
   * getter for user jwt token
   */
  get token() {
    return this._user.token;
  }

  /**
   * getter for users role
   */
  get role() {
    return this._user.role;
  }

  /**
   * getter for users company information
   */
  get company() {
    return this._user.company;
  }

  get user() {
    return this._user.user
  }

  /**
   * getter for users contact information
   */
  get contact() {
    return this._user.contact;
  }


  getUser() {
    return this._user
  }

  /**
   * check role checks if the current user has one of the given roles
   * this is and OR check, not AND!
   *
   * @param roles
   * @returns
   */
  checkRole(roles: string[]) {
    for (let role of roles) {
      if (role == this._user.role) {
        return true;
      }
    }

    return false;
  }
}
