import { OptionFormDtoUpperCase } from './../models/dialog-dto/option-form.dto';
import { OptionFormDto } from 'src/app/models/dialog-dto/option-form.dto';
import { LicenceDashboard } from 'src/app/modules/dashboard/models/licence-dashboard';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogAddClientComponent } from '../components/dialog-add-client/dialog-add-client.component';
import { DialogAddCustomerComponent } from '../components/dialog-add-customer/dialog-add-customer.component';
import { DialogAddLicenceComponent } from '../components/dialog-add-licence/dialog-add-licence.component';
import { RestService } from './rest.service';
import { AddClientDto } from '../models/dialog-dto/add-client.dto';
import { Company } from '../models/company';
import {
  CompanyItemDto,
  CompanyListItemDto,
} from '../models/dialog-dto/company-list-item.dto';
import { DialogDeleteClientComponent } from '../components/dialog-delete-client/dialog-delete-client.component';
import { AddCustomerDto } from '../models/dialog-dto/add-customer.dto';
import { DialogDeleteCustomerComponent } from '../components/dialog-delete-customer/dialog-delete-customer.component';
import {
  CustomerItemDto,
  CustomerListItemDto,
} from '../models/dialog-dto/customer-list-item.dto';
import { CompanySelectDto } from '../models/dialog-dto/company-select.dto';
import { DataService } from './data.service';
import { DeleteLicenceDto, LicenceDto, LicenceDtoUpper } from '../models/licenceDto';
import { DialogDeleteLicenceComponent } from '../components/dialog-delete-licence/dialog-delete-licence.component';
import { AddLicenceDto } from '../models/dialog-dto/add-licence.dto';
import { DialogViewOptionsComponent } from '../components/dialog-view-options/dialog-view-options.component';
import { DialogSetOptionsComponent } from '../components/dialog-set-options/dialog-set-options.component';
import { DialogEditPasswordComponent } from '../components/dialog-edit-password/dialog-edit-password.component';
import { DialogAddOptionsOverviewComponent } from '../components/dialog-add-options-overview/dialog-add-options-overview.component';
import { LicenceFormDto } from '../models/dialog-dto/licence-form.dto';
import { OverviewClientDto } from '../modules/overview/models/overview-client-dto';
import { OverviewCustomerDto } from '../modules/overview/models/overview-customer-dto';
import { DialogExportCsvComponent } from '../components/dialog-export-csv/dialog-export-csv.component';
import { DialogViewContactComponent } from '../components/dialog-view-contact/dialog-view-contact.component';
import { TranslateService } from '@ngx-translate/core';
import { SuccessData } from '../modules/shared/components/success/success.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  constructor(
    private dialog: MatDialog,
    private _restService: RestService,
    private dataService: DataService,
    private translate: TranslateService
  ) {}

  dialogAfterClose(dialog: MatDialogRef<any, any> | undefined, messageEn: string, messageDe: string, successData: SuccessData, change: boolean, result: boolean): [SuccessData, boolean]{
      if (result) {
        if (this.translate.store.currentLang == 'en') {
          successData = {
            element_id: 'dataMsg',
            element_msg: messageEn,
          };
        } else {
          successData = {
            element_id: 'dataMsg',
            element_msg: messageDe,
          };
        }
        change = !change;
      }
    return [successData, change]
  }

  /**
   *
   */
  openAddClient() {
    return this.dialog.open(DialogAddClientComponent);
  }

  openEditPassword(guid: string) {
    return this.dialog.open(DialogEditPasswordComponent, {data: {guid: guid}})
  }

  /**
   *
   * @param guid
   */
  openEditClient(guid: string) {
    return this.dialog.open(DialogAddClientComponent, {
      data: { guid: guid },
    });
  }

  /**
  *  @param guid
  */
  openViewContact(guid: string, viewObject: string) {
    this.dialog.open(DialogViewContactComponent, {data: { guid: guid, viewObject: viewObject }, width: "30rem"})
  }

  /**
   *
   * @param guid
   * @returns
   */
  getEditClientData(id: number | string) {
    return this._restService.get<AddClientDto>(`/clients/${id}`);
  }

  /**
   *
   * @param guid
   * @returns
   */
  getEditClientDataGuid(id: string) {
    return this._restService.get<AddClientDto>(`/clients/${id}`);
  }

  /**
   *
   * @returns
   */
  getClientsList() {
    return this._restService.get<CompanyListItemDto[]>('/clients');
  }

  getLicence(id: string | number) {
    return this._restService.get<LicenceDtoUpper>(`/licences/${id}`)
  }

  getLicenceListByCustomer(id: string | number) {
    return this._restService.get<LicenceDtoUpper[]>(`/licences/byCustomer/${id}`)
  }

  getSelectedClient(id: string | number) {
    return this._restService.get<CompanyItemDto>(`/clients/${id}`);
  }

  getClientByCompanyId(id: number | string) {
    return this._restService.get<CompanyItemDto>(`/clients/company/${id}`)
  }

  openDeleteClient(guid: string) {
    return this.dialog.open(DialogDeleteClientComponent, {
      data: { guid: guid },
    });
  }

  //client is the id of the client which the customer belongs to
  openAddCustomer(client?: string | number) {
    return this.dialog.open(DialogAddCustomerComponent, {data: {client: client} });
  }

  openEditCustomer(guid: string) {
    return this.dialog.open(DialogAddCustomerComponent, {
      data: { guid: guid },
    });
  }

  /**
   *
   * @param guid
   * @returns
   */
  getEditCustomerData(guid: string) {
    return this._restService.get<AddCustomerDto>(`/customers/${guid}`);
  }

  getEditLicenceData(guid: string) {
    return this._restService.get<DeleteLicenceDto>(`/licences/${guid}`);
  }

  /**
   *
   * @returns
   */
  getCustomerList(clientId: number) {
    return this._restService.get<CustomerListItemDto[]>(
      `/customers/byClient/${clientId}`
    );
  }

  getSelectedCustomer(guid: string | number) {
    return this._restService.get<CustomerItemDto>(`/customers/${guid}`);
  }

  getSelectedCustomerByCustomerId(guid: string | number) {
    return this._restService.get<CustomerItemDto>(`/customers/customerid/${guid}`);
  }

  openDeleteCustomer(guid: string) {
    return this.dialog.open(DialogDeleteCustomerComponent, {
      data: { guid: guid },
    });
  }

  openAddLicence(guid?: string) {
    if (guid) {
      return this.dialog.open(DialogAddLicenceComponent, {
        data: {licenceData: {customer_id: guid, vrs: "", project: ""}as unknown as LicenceDto, licenceOptionData: [] as OptionFormDtoUpperCase[]},
      });
    } else {
      return this.dialog.open(DialogAddLicenceComponent);
    }
  }

  openEditLicence(guid: string) {
    let licenceData = this.dataService.getLicenceById(guid) as LicenceDto;
    let licenceOptionData: OptionFormDtoUpperCase[] = [] as OptionFormDtoUpperCase[];
    this.dataService.getOptionsByLicence(guid).subscribe((val) => {
      licenceOptionData.push(...val);
    });

    return this.dialog.open(DialogAddLicenceComponent, {
      data: {licenceData, licenceOptionData },
    });
  }

  openAddOptionsOverview() {
    return this.dialog.open(DialogAddOptionsOverviewComponent, {})
  }

  openDeleteLicence(guid: string) {
    return this.dialog.open(DialogDeleteLicenceComponent, {
      data: { guid: guid },
    });
  }

  openViewOptions(guid: string, client: OverviewClientDto, customer: OverviewCustomerDto) {
    this.dialog.open(DialogViewOptionsComponent, {
      data: {guid: guid, client: client, customer: customer}, width: "60rem"
    })
  }

  openExportCSV(licenceGuid?: string, customerGuid?: number, clientGuid?: number) {
    this.dialog.open(DialogExportCsvComponent, {
      data: {licenceGuid: licenceGuid, customerGuid: customerGuid, clientGuid: clientGuid}, width: "60rem"
    })
  }

  openSetOptions(guid: string){
    return this.dialog.open(DialogSetOptionsComponent, {
      data: {guid: guid},
    })
  }
}
