import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { AddClientDto } from 'src/app/models/dialog-dto/add-client.dto';
import { CompanyFormDto } from 'src/app/models/dialog-dto/company-form.dto';
import { AddCustomerDto } from 'src/app/models/dialog-dto/add-customer.dto';

@Component({
  selector: 'app-dialog-company-form',
  templateUrl: './dialog-company-form.component.html',
  styleUrl: './dialog-company-form.component.scss'
})
export class DialogCompanyFormComponent {
  @Input()
  form: FormGroup;

  @Input()
  initialClient?: CompanyFormDto | undefined

  constructor(private dialogFormService: DialogFormService) {
    this.form = dialogFormService.newCompanyForm();

  }

  undoChanges(formControlName: string, value?: string | number) {
    if (value) {
      this.form.get(formControlName)?.setValue(value);
    } else {
      this.form.get(formControlName)?.setValue("")
    }
  }
}
