import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment.local';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public location: string = '';
  public baseLink: string = '/login';
  public searchOpen: boolean = false;
  public languages: string[] = environment.allowedLanguages;
  private darkMode: boolean = false;

  constructor(
    private _router: Router,
    private currentRoute: ActivatedRoute,
    private _authService: AuthService,
    private _langService: LanguageService,
    private dataService: DataService
  ) {}

  async ngOnInit(): Promise<void> {}

  async ngAfterViewInit(): Promise<void> {
    let leftButton: HTMLElement;
    let rightButton: HTMLElement;
    let path =
      this.currentRoute.snapshot.url[0] != undefined
        ? this.currentRoute.snapshot.url[0].path
        : '';

    await new Promise((r) => {
      var getElements = setInterval(() => {
        leftButton = document.getElementById('tableIcon') as HTMLElement;
        rightButton = document.getElementById('settingsIcon') as HTMLElement;

        if (leftButton && rightButton) {
          if (path == 'administration' || path == 'settings') {
            leftButton.style.backgroundColor = '#b6b6b6';
            rightButton.style.backgroundColor = '#b6123e';
          } else {
            rightButton.style.backgroundColor = '#b6b6b6';
            leftButton.style.backgroundColor = '#b6123e';
          }
          clearInterval(getElements);
        }
      }, 100);
      return r;
    });
  }

  logout() {
    this._authService.reset();
    this._router.navigate(['/login']);
  }
  profile() {
    this._router.navigate(['/profile', this.userGuid]);

    // this.dataService.getUser()

    // this._router.navigate(['profile/'])
  }

  toOverview() {
    this._router.navigate(['/overview']);
  }

  toAdministration() {
    this._router.navigate(['/profile/administration/', this.userGuid]);
  }

  toggleDarkMode() {
    this.darkMode = !this.darkMode;
    document.querySelector('html')?.classList.toggle('dark');
  }

  changeLanguage(l: string) {
    this._langService.currentLanguage = l;
  }

  get isAuthenticated() {
    return this._authService?.contact?.email;
  }

  get firstname() {
    return this._authService.contact.firstname;
  }

  get lastname() {
    return this._authService.contact.lastname;
  }

  get email() {
    return this._authService.contact.email;
  }

  get userGuid() {
    return this._authService.user.guid;
  }

//   Test() {
//     const data = {
//       vrs: "23542352345234523542354",
//       timestamp: Math.floor(Date.now() / 1000),
//       usage: {
//         export: Math.ceil(Math.random() * 10),
//         audio: Math.ceil(Math.random() * 5) + 25,
//         recording: Math.ceil(Math.random() * 15),
//       },
//     }
//     this.dataService.test(data).subscribe(val => {
//       console.log(val);
//     })
//   }
}
