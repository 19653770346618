import { ShareClientId } from './../../services/data/companies.service';
import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Form, FormGroup } from '@angular/forms';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import { AddClientDto } from 'src/app/models/dialog-dto/add-client.dto';
import { TranslateService } from '@ngx-translate/core';
import { SuccessData } from 'src/app/modules/shared/components/success/success.component';

interface AddClientDialogData {
  guid: string;
}

@Component({
  selector: 'app-dialog-add-client',
  templateUrl: './dialog-add-client.component.html',
  styleUrl: './dialog-add-client.component.scss',
})
export class DialogAddClientComponent {
  companyForm: FormGroup;
  contactForm: FormGroup;
  guid: string = '';

  initialClient: AddClientDto | undefined;

  constructor(
    private dialogFormService: DialogFormService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<DialogAddClientComponent>,
    private shareClientId: ShareClientId,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: AddClientDialogData
  ) {
    this.companyForm = this.dialogFormService.newCompanyForm();
    this.contactForm = this.dialogFormService.newContactForm();

    if (data?.guid) {
      this.guid = data.guid;

      this.dialogService.getEditClientDataGuid(data.guid).subscribe((data) => {
        this.initialClient = data;
        this.companyForm.get('id')?.setValue(data.company.id);
        this.companyForm.get('name')?.setValue(data.company.name);
        this.companyForm.get('country')?.setValue(data.company.country);
        this.companyForm.get('city')?.setValue(data.company.city);
        this.companyForm.get('zipcode')?.setValue(data.company.zipcode);
        this.companyForm.get('street')?.setValue(data.company.street);

        this.contactForm.get('id')?.setValue(data.contact.id);
        this.contactForm.get('email')?.setValue(data.contact.email);
        this.contactForm.get('firstname')?.setValue(data.contact.firstname);
        this.contactForm.get('lastname')?.setValue(data.contact.lastname);
        this.contactForm.get('phone')?.setValue(data.contact.phone);
        this.contactForm.get('handy')?.setValue(data.contact.handy);
      });
    }
  }

  save() {
    this.dialogFormService
      .saveAddClientForm(this.companyForm, this.contactForm, this.guid)
      .subscribe((result) => {
        if (result.success) {
          this.dialogRef.close(result.success);
        }
      });
  }
}
