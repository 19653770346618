import { ShareClientId } from 'src/app/services/data/companies.service';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableModule } from '@angular/material/table';
import { Subscription } from 'rxjs';
import {
  OptionFormDto,
  OptionFormDtoUpperCase,
} from 'src/app/models/dialog-dto/option-form.dto';
import { DataService } from 'src/app/services/data.service';
import { OptionType, OptionTypeUpper } from 'src/app/models/option-type';
import { OptionVersion } from 'src/app/models/option-version';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-mat-table-options',
  templateUrl: './mat-table-options.component.html',
  styleUrls: ['./mat-table-options.component.scss'],
})
export class MatTableOptionsComponent implements OnInit {
  @Input() verify!: boolean;

  displayedColumns = [
    'OptionTypeID',
    'OptionVersionID',
    'Value',
    'StartDate',
    'EndDate',
  ];

  dataTypes: OptionTypeUpper[] = [];
  dataVersions: OptionVersion[] = [];
  dataSource: OptionFormDtoUpperCase[] = [];

  constructor(
    private shareClientId: ShareClientId,
    public dataService: DataService
  ) {}

  @ViewChild(MatTable) table!: MatTable<OptionFormDtoUpperCase>;

  removeFromCurrentList(option: OptionFormDtoUpperCase) {
    const index = this.shareClientId.tmpDataList.indexOf(option);
    if (index !== -1) {
      this.shareClientId.tmpDataList.splice(index, 1);
      this.setDataSource();
      this.shareClientId.resetTmpData();
      this.table.renderRows();
    }
  }

  ngOnInit(): void {
    if (!this.verify) {
      this.displayedColumns.push('delete');
    }

    this.shareClientId.tmpDataOptions$.subscribe((options) => {
      if (Object.keys(options).length !== 0 && !this.verify) {
        if (!this.shareClientId.tmpDataList.some((item) => item === options)) {
          this.shareClientId.tmpDataList.push(options);
        }
      }
      this.setDataSource();
    });

    // Initial data source setup
    this.setDataSource();
  }

  private async setDataSource(): Promise<void> {
    const sleep = async () => {
        await new Promise((r) => setTimeout(r, 200));
    };

    // Wait a little before setting the data source
    await sleep();
    this.dataSource = [...this.shareClientId.tmpDataList];

    // Initialize data arrays
    this.dataTypes = [];
    this.dataVersions = [];

    // Create an array of promises to track the async calls
    const promises = this.dataSource.map((data) => {
        return new Promise<void>((resolve) => {
            const optionVersion = this.dataService.getOptionVersionById(data.OptionVersionID as number);

            this.dataService.getOptionTypeByIDRest(data.OptionTypeID as number).subscribe((type) => {
                if (type && !(this.dataTypes.includes(type))) {
                    this.dataTypes.push(type);
                }
                if (optionVersion) {
                    this.dataVersions.push(optionVersion);
                }

                // Format dates
                data.StartDate = data.StartDate.split('T')[0];
                data.EndDate = data.EndDate.split('T')[0];

                resolve(); // Resolve this specific promise when data is processed
            });
        });
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    // After all data is loaded, render the rows
    this.table.renderRows()
}

}
