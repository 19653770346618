import { ShareClientId } from 'src/app/services/data/companies.service';
import { DataService } from './../../services/data.service';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CompanyItemDto } from 'src/app/models/dialog-dto/company-list-item.dto';
import { CustomerListItemDto } from 'src/app/models/dialog-dto/customer-list-item.dto';
import { ExportCSV } from 'src/app/models/export-csv';
import { LicenceDtoUpper } from 'src/app/models/licenceDto';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { DialogService } from 'src/app/services/dialog.service';

interface ExportData {
  licenceGuid: string;
  customerGuid: number;
  clientGuid: number;
}

@Component({
  selector: 'app-dialog-export-csv',
  templateUrl: './dialog-export-csv.component.html',
  styleUrl: './dialog-export-csv.component.scss',
})
export class DialogExportCsvComponent {
  form: FormGroup;

  availableCustomers: CustomerListItemDto[] = [];
  availableLicences: LicenceDtoUpper[] = [];

  selectedCustomers: CustomerListItemDto[] = [];
  selectedLicences: LicenceDtoUpper[] = [];
  selectedClient: CompanyItemDto = {} as CompanyItemDto;

  allCusSelected = true;
  allLicSelected = true;

  constructor(
    private dialogFormService: DialogFormService,
    private dialogService: DialogService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private shareClientId: ShareClientId,
    @Inject(MAT_DIALOG_DATA) public data: ExportData
  ) {
    this.form = this.dialogFormService.newExportCSVForm();

    this.form
      .get('violationStart')
      ?.setValue(this.shareClientId.initialRangeStartExport);
    this.form
      .get('usageStart')
      ?.setValue(this.shareClientId.initialRangeStartExport);
    this.form
      .get('violationEnd')
      ?.setValue(this.shareClientId.initialRangeEndExport);
    this.form
      .get('usageEnd')
      ?.setValue(this.shareClientId.initialRangeEndExport);

    this.dialogService
      .getClientByCompanyId(this.data.clientGuid)
      .subscribe((company) => {
        this.selectedClient = company;
        this.dialogService
          .getCustomerList(company.client_id)
          .subscribe((customers) => {
            this.availableCustomers.push(...customers);
            this.allCustomers();
            this.allLicences();
          });
      });

    if (this.data.licenceGuid) {
      this.dialogService
        .getLicenceListByCustomer(this.data.customerGuid)
        .subscribe((licences) => {
          this.selectedLicences = licences.filter(
            (l) => l.GUID == this.data.licenceGuid
          );
        });
    }

    if (this.data.customerGuid) {
      this.dialogService
        .getClientByCompanyId(this.data.clientGuid)
        .subscribe((client) => {
          this.dialogService
            .getCustomerList(client.client_id)
            .subscribe((customers) => {
              this.appendCustomers(
                customers.filter(
                  (c) => c.company.id == this.data.customerGuid
                )[0]
              );
            });
        });
    }
  }

  undoChanges(formControlName: string, value?: string | number) {
    if (value) {
      this.form.get(formControlName)?.setValue(value);
    } else {
      this.form.get(formControlName)?.setValue('');
    }
  }

  appendCustomers(client: CustomerListItemDto) {
    if (this.allCusSelected) {
      this.selectedCustomers = [];
      this.availableLicences = [];
      this.allCusSelected = false;
    }

    if (!this.selectedCustomers.some((c) => c === client)) {
      this.selectedCustomers?.push(client);
    }

    this.availableLicences = this.setAvailableLicences(client.company.id)
    this.allLicences()
    this.cdr.detectChanges();
  }

  setAvailableLicences(company_id: string | number): LicenceDtoUpper[] {
    this.dialogService
      .getLicenceListByCustomer(company_id)
      .subscribe((licences) => {
        licences.forEach((licence) => {
          if (!this.availableLicences.some((c) => c === licence)) {
            this.availableLicences?.push(licence);
            this.cdr.detectChanges();
          }
        });
      });
      return this.availableLicences.filter((item, index, self) => index === self.findIndex((t) => t.GUID === item.GUID))
  }

  allCustomers() {
    this.selectedCustomers = this.availableCustomers;
    this.allCusSelected = true;
  }

  removeCustomer(client: CustomerListItemDto) {
    this.selectedCustomers = this.selectedCustomers?.filter((c) => c !== client);
    if (this.selectedCustomers.length == 0) {
      this.allCustomers();
    } else {
    this.availableLicences = this.availableLicences.filter((c) => c.CustomerID !== client.id);
    this.selectedLicences = this.selectedLicences.filter((c) => c.CustomerID !== client.id);
    }
    this.allLicences()
    this.cdr.detectChanges(); // This ensures the view is updated.
  }

  appendLicence(licence: LicenceDtoUpper) {
    if (this.allLicSelected) {
      this.selectedLicences = [];
      this.allLicSelected = false;
    }

    if (!this.selectedLicences.some((c) => c === licence)) {
      this.selectedLicences?.push(licence);
      this.cdr.detectChanges();
    }
  }

  async allLicences(clear?: boolean) {
    if (clear || this.selectedLicences.length == 0) {
      this.selectedLicences = this.availableLicences
      this.allLicSelected = true;
    }
    this.selectedCustomers.forEach(customer => {
      this.availableLicences = this.setAvailableLicences(customer.company.id)
    })
    await new Promise(r => setTimeout(r, 200))
    if(this.selectedLicences.length > 0) {
      this.selectedLicences = this.availableLicences.filter(al => this.selectedLicences.some(sl => JSON.stringify(sl) === JSON.stringify(al)))
      this.selectedLicences = this.selectedLicences.filter((item, index, self) => index === self.findIndex((t) => t.GUID === item.GUID))

    }
    if(this.selectedLicences.length == 0) {
      this.allLicSelected = true;
      this.selectedLicences = this.availableLicences;
    }
  }

  removeLicence(licence: LicenceDtoUpper) {
    this.selectedLicences = this.selectedLicences?.filter((c) => c !== licence);
    if (this.selectedLicences.length == 0) {
      this.allLicences();
    }
    this.cdr.detectChanges(); // This ensures the view is updated.
  }

  // export_excel() {
  //   this.dataService.downloadCSV(
  //     this.dashboard?.licence.guid as string,
  //     'licence'
  //   );
  // }

  export() {
    let urlPrefix = 'licence';
    let exportTypeID = this.selectedLicences[0].GUID;
    let data: ExportCSV = {
      client: this.selectedClient,
      customers: this.selectedCustomers,
      licences: this.selectedLicences,
      violation_start: this.form.get('violationStart')?.value
        ? this.form.get('violationStart')?.value
        : '',
      violation_end: this.form.get('violationEnd')?.value
        ? this.form.get('violationEnd')?.value
        : '',
      usage_start: this.form.get('usageStart')?.value
        ? this.form.get('usageStart')?.value
        : '',
      usage_end: this.form.get('usageEnd')?.value
        ? this.form.get('usageEnd')?.value
        : '',
    };
    if (!this.data.licenceGuid) {
      urlPrefix = 'customer';
      exportTypeID = this.selectedCustomers[0].guid;
    }
    if (!this.data.customerGuid) {
      urlPrefix = 'client';
      exportTypeID = this.data.clientGuid.toString();
    }

    this.dataService.downloadCSV(exportTypeID, urlPrefix, data);
  }
}
