<h1 mat-dialog-title class="dialog-header" *ngIf="!guid">{{"Add Client" | translate}}</h1>
<h1 mat-dialog-title class="dialog-header" *ngIf="guid">{{"Edit Client" | translate }}</h1>

<mat-dialog-content class="mat-typography">
  <mat-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="companyForm">
      <ng-template matStepLabel>{{"Company"| translate}}</ng-template>

      <app-dialog-company-form [form]="companyForm" [initialClient]="initialClient?.company"></app-dialog-company-form>

      <div class="float-right">
        <button mat-button mat-dialog-close>{{"Cancel" | translate}}</button>
        <button mat-flat-button matStepperNext cdkFocusInitial color="primary" class="ml-2">
          {{"Next" | translate}}
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="contactForm">
      <ng-template matStepLabel>{{"Contact"|translate}}</ng-template>

      <app-dialog-contact-form [form]="contactForm" [initialContact]="initialClient?.contact"></app-dialog-contact-form>

      <div class="float-right">
        <button mat-button mat-dialog-close>{{"Cancel" | translate}}</button>
        <button
          mat-stroked-button
          matStepperPrevious
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
          {{"Back" | translate}}
        </button>
        <button mat-flat-button matStepperNext cdkFocusInitial color="primary" class="ml-2">
          {{"Next" | translate}}
        </button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{"Confirmation"|translate}}</ng-template>

      <div>
        <h3>{{"Verify" |translate}} </h3>
        <h4>{{"Company" | translate}}</h4>
        <p>
          {{ companyForm.get('name')?.value }}<br>
          {{ companyForm.get('street')?.value }}<br>
          {{ companyForm.get('zipcode')?.value }} {{ companyForm.get('city')?.value }}<br>
          {{ companyForm.get('country')?.value }}
        </p>
        <h4>Contact</h4>
        <p>
          {{ contactForm.get('firstname')?.value }} {{ contactForm.get('lastname')?.value }}<br>
          {{ contactForm.get('email')?.value }}<br>
          {{ contactForm.get('phone')?.value }}<br>
          {{ contactForm.get('handy')?.value }}
        </p>
      </div>

      <div class="float-right">
        <button mat-button mat-dialog-close>{{"Cancel" | translate}}</button>
        <button
          mat-stroked-button
          matStepperPrevious
          cdkFocusInitial
          color="primary"
          class="ml-2"
        >
          {{"Back" | translate}}
        </button>
        <button mat-flat-button cdkFocusInitial color="primary" class="ml-2" (click)="save()">{{"Save" | translate}}</button>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
