import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogFormService } from 'src/app/services/dialog-form.service';
import { DialogAddClientComponent } from '../dialog-add-client/dialog-add-client.component';
import { DialogService } from 'src/app/services/dialog.service';
import { Company } from 'src/app/models/company';
import { AddClientDto } from 'src/app/models/dialog-dto/add-client.dto';
import {
  CompanyItemDto,
  CompanyListItemDto,
} from 'src/app/models/dialog-dto/company-list-item.dto';
import { CustomerItemDto } from 'src/app/models/dialog-dto/customer-list-item.dto';

interface AddCustomerDialogData {
  guid: string;
  client: string;
}

@Component({
  selector: 'app-dialog-add-customer',
  templateUrl: './dialog-add-customer.component.html',
  styleUrl: './dialog-add-customer.component.scss',
})
export class DialogAddCustomerComponent {
  guid: string = '';
  clientSelectForm: FormGroup;
  companyForm: FormGroup;
  contactForm: FormGroup;

  initialClient: CompanyItemDto | undefined;
  initialCustomer: AddClientDto | undefined

  constructor(
    public dialogService: DialogService,
    private dialogFormService: DialogFormService,
    public dialogRef: MatDialogRef<DialogAddClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddCustomerDialogData
  ) {
    this.clientSelectForm = this.dialogFormService.newCompanySelectForm();
    this.companyForm = this.dialogFormService.newCompanyForm();
    this.contactForm = this.dialogFormService.newContactForm();

    if (data?.client) {
      this.dialogService.getSelectedClient(data.client).subscribe((client) => {
        this.clientSelectForm.get('id')?.setValue(client.company.id);
        this.initialClient = client;
      });
    }

    if (data?.guid) {
      this.guid = data.guid;
      this.dialogService
        .getEditCustomerData(data.guid)
        .subscribe((customer) => {
          this.initialCustomer = customer
          this.setInitialData(customer, this.companyForm, this.contactForm);

          this.dialogService
            .getSelectedClient(customer.client.id)
            .subscribe((client) => {

              this.clientSelectForm.get('id')?.setValue(client.company.id);
              this.initialClient = client;
            });
        });
    }
  }
  setInitialData(customer: AddClientDto, form: FormGroup, form2: FormGroup) {
    form.get('name')?.setValue(customer.company.name);
    form.get('country')?.setValue(customer.company.country);
    form.get('city')?.setValue(customer.company.city);
    form.get('zipcode')?.setValue(customer.company.zipcode);
    form.get('street')?.setValue(customer.company.street);

    form2.get('email')?.setValue(customer.contact.email);
    form2.get('firstname')?.setValue(customer.contact.firstname);
    form2.get('lastname')?.setValue(customer.contact.lastname);
    form2.get('phone')?.setValue(customer.contact.phone);
    form2.get('handy')?.setValue(customer.contact.handy);
  }

  save() {
    var clientId: number = 0;
    this.dialogService
      .getClientByCompanyId(this.clientSelectForm.get('id')?.value)
      .subscribe((client) => {
        clientId = client.client_id;
        this.dialogFormService
          .saveAddCustomerForm(
            clientId,
            this.companyForm,
            this.contactForm,
            this.guid
          )
          .subscribe((result: { success: any }) => {
            if (result.success) {
              this.dialogRef.close(result.success);
            }
            return result
          });
      });
  }
}
