<h1 mat-dialog-title class="dialog-header">
  {{ "downloadExcel" | translate }}
</h1>

<mat-dialog-content class="mat-typography" style="padding-bottom: 0 !important">
  <form [formGroup]="form">
    <div class="flex">
      <div class="w-full">
        <h3 class="ml-8">{{ "Violation Date Range" | translate }}</h3>
        <div class="ml-8">
          <mat-form-field style="width: 87%;">
            <mat-date-range-input [rangePicker]="Violationpicker">
              <input
                matStartDate
                placeholder="Violation Start"
                formControlName="violationStart"
              />
              <input
                matEndDate
                placeholder="Violation End"
                formControlName="violationEnd"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="Violationpicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #Violationpicker></mat-date-range-picker>
            <button
              mat-icon-button
              class="undoButton"
              *ngIf="
                form.get('violationStart')?.dirty &&
                form.get('violationStart')?.value != ''
              "
              (click)="
                undoChanges('violationStart', '');
                undoChanges('violationEnd', '');
                $event.stopPropagation()
              "
            >
              <mat-icon>undo</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="w-full">
        <h3 class="ml-8">{{ "Usage Date Range" | translate }}</h3>
        <div class="ml-8">
          <mat-form-field style="width: 87%;">
            <mat-date-range-input [rangePicker]="UsagePicker">
              <input
                matStartDate
                placeholder="Usage Start"
                formControlName="usageStart"
              />
              <input
                matEndDate
                placeholder="Usage End"
                formControlName="usageEnd"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="UsagePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #UsagePicker></mat-date-range-picker>
            <button
              mat-icon-button
              class="undoButton"
              *ngIf="
                form.get('usageStart')?.dirty &&
                form.get('usageStart')?.value != ''
              "
              (click)="
                undoChanges('usageStart', '');
                undoChanges('usageEnd', '');
                $event.stopPropagation()
              "
            >
              <mat-icon>undo</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>

    <h3 *ngIf="!data.customerGuid" class="ml-8">
      {{ "Customers" | translate }}
    </h3>
    <div *ngIf="!data.customerGuid" class="flex justify-around gap-4">
      <mat-form-field class="w-2/5 h-20 max-h-20">
        <mat-label>{{ "Customer" | translate }}</mat-label>
        <mat-select formControlName="customerId">
          <mat-option (click)="allCustomers()">{{"All Customers"| translate}}</mat-option>
          @for (client of availableCustomers; track $index) {
          <mat-option [value]="" (click)="appendCustomers(client)">{{
            client.company.name
          }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <div
        class="flex w-2/5 rounded-md p-4 flex-wrap gap-2 overflow-scroll max-h-24 h-24 -mt-6"
        style="background-color: #f2f2f2"
      >
      <div class="selectedItems" *ngIf="allCusSelected">
        {{"All Customers"| translate}}
      </div>
        @for (customer of selectedCustomers; track $index) {
        <div class="selectedItems" *ngIf="!allCusSelected">
          {{ customer.company.name }} |
          <span style="cursor: pointer" (click)="removeCustomer(customer)"
            >x</span
          >
        </div>
        }
      </div>
    </div>

    <h3 *ngIf="!data.licenceGuid" class="ml-8">{{ "Licences" | translate }}</h3>
    <div *ngIf="!data.licenceGuid" class="flex justify-around gap-4">
      <mat-form-field class="w-2/5 h-20 max-h-20">
        <mat-label>{{ "Licence" | translate }}</mat-label>
        <mat-select formControlName="licenceId">
          <mat-option (click)="allLicences(true)">{{"All Licences"| translate}}</mat-option>
          @for (licence of availableLicences; track $index) {
          <mat-option [value]="" (click)="appendLicence(licence)">{{
            licence.Project
          }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <div
        class="flex w-2/5 rounded-md p-4 flex-wrap gap-2 overflow-scroll max-h-24 h-24 -mt-6"
        style="background-color: #f2f2f2"
      >
      <div class="selectedItems" *ngIf="allLicSelected">
        {{"All Licences"| translate}}
      </div>
        @for (licence of selectedLicences; track $index) {
        <div class="selectedItems" *ngIf="!allLicSelected">
          {{ licence.Project }} |
          <span style="cursor: pointer" (click)="removeLicence(licence)"
            >x</span
          >
        </div>
        }
      </div>
    </div>
  </form>

  <div class="w-full float-right bottomButton">
    <button
    mat-flat-button
    color="primary"
    class="ml-2 float-right"
    (click)="export()"
  >
    {{ "Export" | translate }}
  </button>
    <button
      mat-button
      mat-dialog-close
      color="primary"
      class="ml-2 float-right"
    >
      {{ "Cancel" | translate }}
    </button>
  </div>
</mat-dialog-content>
