import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEnGb from '@angular/common/locales/en-GB';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnExtra from '@angular/common/locales/extra/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel, MatSnackBarModule
} from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { DialogSetOptionsComponent } from './components/dialog-set-options/dialog-set-options.component';
import { DialogViewOptionsComponent } from './components/dialog-view-options/dialog-view-options.component';
import { DialogAddClientComponent } from './components/dialog-add-client/dialog-add-client.component';
import { DialogAddCustomerComponent } from './components/dialog-add-customer/dialog-add-customer.component';
import { DialogAddLicenceComponent } from './components/dialog-add-licence/dialog-add-licence.component';
import { DialogAddOptionComponent } from './components/dialog-add-option/dialog-add-option.component';
import { DialogCompanyFormComponent } from './components/dialog-company-form/dialog-company-form.component';
import { DialogCompanySelectComponent } from './components/dialog-company-select/dialog-company-select.component';
import { DialogCustomerSelectComponent } from './components/dialog-customer-select/dialog-customer-select.component';
import { DialogContactFormComponent } from './components/dialog-contact-form/dialog-contact-form.component';
import { DialogDeleteClientComponent } from './components/dialog-delete-client/dialog-delete-client.component';
import { DialogDeleteCustomerComponent } from './components/dialog-delete-customer/dialog-delete-customer.component';
import { DialogDeleteLicenceComponent } from './components/dialog-delete-licence/dialog-delete-licence.component';
import { DialogLicenceFormComponent } from './components/dialog-licence-form/dialog-licence-form.component';
import { DialogOptionsFormComponent } from './components/dialog-options-form/dialog-options-form.component';
import { DialogOptionsListComponent } from './components/dialog-options-list/dialog-options-list.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundErrorComponent } from './components/not-found-error/not-found-error.component';
import { NotificationComponent } from './components/notification/notification.component';
import { HttpLoaderFactory } from './factories/http-loader.factory';
import { HttpInterceptorProviders } from './interceptors';
import { SharedModule } from './modules/shared/shared.module';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { DialogService } from './services/dialog.service';
import { LanguageService } from './services/language.service';
import { LoginService } from './services/login.service';
import { NotificationService } from './services/notification.service';
import { RestService } from './services/rest.service';
import { MatTableModule } from '@angular/material/table';
import { DialogVerifyLicenceComponent } from "./components/dialog-verify-licence/dialog-verify-licence.component";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableOptionsComponent } from "./components/mat-table-options/mat-table-options.component";
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordConfirmComponent } from './components/reset-password-confirm/reset-password-confirm.component';
import { DialogEditPasswordComponent } from './components/dialog-edit-password/dialog-edit-password.component';
import { MatDividerModule } from '@angular/material/divider';
import { DialogAddOptionsOverviewComponent } from './components/dialog-add-options-overview/dialog-add-options-overview.component';
import { DialogLicenceSelectComponent } from './components/dialog-licence-select/dialog-licence-select.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableUserComponent } from './components/mat-table-user/mat-table-user.component';
import { MatTableLicenceTypesComponent } from './components/mat-table-licence-types/mat-table-licence-types.component';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DialogExportCsvComponent } from './components/dialog-export-csv/dialog-export-csv.component';
import { MatDatepickerModule, MatDateRangeInput, MatDateRangePicker } from '@angular/material/datepicker';
import { DialogViewContactComponent } from './components/dialog-view-contact/dialog-view-contact.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    ResetPasswordConfirmComponent,
    NotFoundErrorComponent,
    DialogEditPasswordComponent,
    DialogSetOptionsComponent,
    DialogViewOptionsComponent,
    DialogAddClientComponent,
    DialogAddCustomerComponent,
    DialogAddLicenceComponent,
    DialogAddOptionComponent,
    DialogAddOptionsOverviewComponent,
    DialogContactFormComponent,
    DialogCompanyFormComponent,
    DialogLicenceFormComponent,
    DialogOptionsFormComponent,
    DialogOptionsListComponent,
    DialogCompanySelectComponent,
    DialogCustomerSelectComponent,
    DialogDeleteClientComponent,
    DialogDeleteCustomerComponent,
    DialogDeleteLicenceComponent,
    DialogLicenceSelectComponent,
    DialogExportCsvComponent,
    NotificationComponent,
    MatTableOptionsComponent,
    DialogVerifyLicenceComponent,
    DialogViewContactComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgChartsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
        },
    }),
    // Material Design Modules
    ScrollingModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatInputModule,
    MatStepperModule,
    SharedModule,
    MatDividerModule,
    MatSnackBarLabel,
    MatSnackBarActions,
    MatSnackBarAction,
    MatSelectModule,
    MatTableModule,
    MatOptionModule,
    MatPaginatorModule,
    MatSortModule,
    MatDateRangeInput,
    MatDateRangePicker,
    MatDatepickerModule,
    MatNativeDateModule,
],
  providers: [
    HttpClient,
    HttpInterceptorProviders,
    RestService,
    DataService,
    AuthService,
    NotificationService,
    CookieService,
    LanguageService,
    LoginService,
    DialogService,
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (languageService: LanguageService) =>
        languageService.getLocale(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // register locale data for proper formating of date, time and numbers
    registerLocaleData(localeEnGb, 'en-gb', localeEnExtra);
    registerLocaleData(localeDe, 'de-de', localeDeExtra);
  }
}
